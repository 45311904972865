import {
  IColorPickerGridCellStyleProps,
  IColorPickerGridCellStyles,
} from 'office-ui-fabric-react/lib/SwatchColorPicker';

export const ColorPickerGridCellStyles = (
  props: IColorPickerGridCellStyleProps,
): Partial<IColorPickerGridCellStyles> => {
  return {};
};
